<template>
    <div class="photoqr">
        <div class="photoqr-header">
            <van-nav-bar @click-left="back">
                <template #left>
                    <van-icon name="arrow-left" size="19"/>
                    返回
                </template>
            </van-nav-bar>
        </div>
        <div class="info-txt" v-if="type<3">
            <span>请务必保证所拍摄照片包含清晰的科目信息和完整的二维码与二维码底部编号</span>，
            确认符合要求后点击【确认上传】。
        </div>
        <div class="info-txt" v-if="type==3">
            <span>请务必保证所拍摄照片包含清晰、完整的二维码与二维码底部编号</span>，
            确认符合要求后点击【确认上传】。
        </div>

        <van-uploader
                ref="uploadImg"
                v-model="fileList"
                max-count="1"
                capture="camera"
                accept="image/*"
                :before-read="beforeReadAnswer"
                :after-read="afterReadAnswer"
        >
            <van-icon name="plus"/>
        </van-uploader>

        <div class="btn-area">
            <van-button color="#563275" block size="normal" type="primary" @click="confirmHandle">
                确定上传
            </van-button>
        </div>
    </div>
</template>

<script>
    import {Toast} from "vant";
    import {getSignature} from "r/index/index";
    import {fileUpload} from "r/index/upload";
    import {scanPhoto, scanJiaoJuanPhoto, scanPaper} from "r/index/photoqr";
    import {nanoid} from "nanoid";

    export default {
        name: "photoqr",
        data() {
            return {
                type: 1,
                fileList: [],
                code: null
            };
        },
        created() {
            this.type = this.$route.query.type;
        },
        methods: {
            // 确认-拍照识别二维码真伪
            confirmHandle() {
                if (!this.fileList || this.fileList == [] || this.fileList.length == 0) {
                    return Toast('请先拍照上传')
                }
                if (this.type == 1) {
                    // 试卷确认
                    this.scanRequest(scanPhoto);
                } else if (this.type == 2) {
                    // 交卷登记
                    this.scanRequest(scanJiaoJuanPhoto);
                } else if (this.type == 3) {
                    // 试卷袋确认
                    this.scanPaperHandle()
                }
            },
            // 试卷袋确认-拍照识别
            async scanPaperHandle() {
                let {data: res} = await scanPaper(this.fileList[0]['url'])
                console.log(res)
                if (res.code != 200) {
                    return Toast.fail(res.data);
                }
                Toast.success(res.data);
                await this.$router.replace('/monitor')
            },
            async scanRequest(requestName) {
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                let {data: res} = await requestName(this.fileList[0]['url'])
                console.log(res)
                if (res.code == 201 && this.type == 2) {
                    this.$store.state.timerUpload && window.clearInterval(this.$store.state.timerUpload);
                    this.$store.state.timerUpload = window.setInterval(() => {
                        if (this.$store.state.timeUpload > 0) {
                            this.$store.state.timeUpload--;
                            window.localStorage.setItem('monitor-timeUpload-' + zkz + '-' + subjectUuid, this.$store.state.timeUpload);
                        } else {
                            window.clearInterval(this.$store.state.timerUpload)
                        }
                    }, 1000)
                    return await this.$router.replace('/upload');
                }
                if (res.code == 202 && this.type == 2) {
                    this.$store.state.timerUpload = 0;
                    window.localStorage.setItem('monitor-timeUpload-' + zkz + '-' + subjectUuid, this.$store.state.timeUpload);
                    return await this.$router.replace('/upload');
                }
                if (res.code != 200) {
                    return Toast.fail(res.data);
                }
                Toast.success(res.data);
                await this.$router.replace('/monitor')
            },
            // 上传作品-读取文件
            beforeReadAnswer(file) {
                this.loading = true;
                return file;
            },
            // 上传作品
            afterReadAnswer(file) {
                this.uploadHandle(file);
            },
            async uploadHandle(file) {
                file.status = 'uploading';
                file.message = '识别中...';
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                const fileName = 'qrcode/' + zkz + '_' + subjectUuid + '_' + nanoid() + '.jpg';

                let signatureOss = window.localStorage.getItem('monitor-signature-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem('monitor-subjectUuId'));
                if (signatureOss && signatureOss !== '{}' && Object.keys(JSON.parse(signatureOss)).length > 0) {
                    const data = JSON.parse(signatureOss);
                    await this.uploadRequest(data, file, fileName)
                } else {
                    const {data} = await getSignature();
                    window.localStorage.setItem('monitor-signature-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem('monitor-subjectUuId'), JSON.stringify(data));
                    await this.uploadRequest(data, file, fileName)
                }
            },
            // 上传请求
            async uploadRequest(data, file, fileName) {
                const uploadData = await fileUpload(data, file.file, fileName);
                console.log(uploadData)
                if (uploadData.data.Status === 'OK') {
                    this.loading = false
                    file.fileName = fileName;
                    this.loading = false;
                    file.status = 'success';
                    this.fileList = [{
                        url: uploadData.data.url,
                        status: 'success'
                    }];
                    Toast.success('上传成功');
                } else {
                    this.loading = false;
                    file.status = 'failed';
                    file.message = '上传失败';
                    Toast.fail('上传失败');
                }
            },
            back() {
                this.$router.replace('/qrcode?type=' + this.type);
            },
        },
    }
</script>

<style scoped lang="scss">
    .photoqr {
        padding: 56px 0 84px 0;
        min-height: 100%;
    }

    ::v-deep .photoqr-header {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000;
        width: 100%;

        .van-nav-bar {
            background-color: transparent;
        }

        .van-hairline--bottom::after {
            border-bottom-width: 0;
        }

        .van-nav-bar__right,
        .van-nav-bar__left,
        .van-icon-arrow-left {
            color: #8b2d87 !important;
        }
    }

    ::v-deep .van-uploader {
        display: block;
        margin: 40px auto 0;
        width: 154px;
        height: 154px;
        border: 2px dashed #563279;

        .van-uploader__input-wrapper {
            position: relative;
            width: 150px;
            height: 150px;
            margin: 0;

            .van-icon {
                font-size: 50px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #563279;
            }
        }

        .van-uploader__preview {
            width: 150px;
            height: 150px;
            margin: 0;

            .van-image {
                width: 100%;
                height: 100%;
                vertical-align: middle;
            }

            .van-uploader__preview-delete {
                width: 20px;
                height: 20px;
                border-radius: 0 0 0 18px;

                .van-uploader__preview-delete-icon {
                    top: 0px;
                    right: -1px;
                }
            }
        }
    }

    .btn-area {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-flow: row;

        .van-button {
            margin: 20px 16px;
            flex: 1;
            border-radius: 8px;
        }
    }

    .info-txt {
        font-size: 13px;
        text-align: left;
        line-height: 20px;
        margin-bottom: 20px;
        padding: 0 16px;

        span {
            color: #F5A43C;
        }
    }
</style>

