import {request} from "./network";

// 拍照识别二维码-试卷确认
export function scanPhoto(imageUrl) {
    let zkzNum = localStorage.getItem("monitor-zkz");
    let subjectUuId = localStorage.getItem("monitor-subjectUuId");
    return request({
        method: "get",
        url: "/scan/" + subjectUuId + "/" + zkzNum + "?imageUrl=" + imageUrl,
    });
}

// 拍照识别二维码-交卷登记
export function scanJiaoJuanPhoto(imageUrl) {
    let zkzNum = localStorage.getItem("monitor-zkz");
    let subjectUuId = localStorage.getItem("monitor-subjectUuId");
    return request({
        method: "get",
        url: "/scanJiaoJuan/" + subjectUuId + "/" + zkzNum + "?imageUrl=" + imageUrl,
    });
}

// 拍照识别二维码-试卷袋确认
export function scanPaper(imageUrl) {
    let zkzNum = localStorage.getItem("monitor-zkz");
    let subjectUuId = localStorage.getItem("monitor-subjectUuId");
    return request({
        method: "get",
        url: "/scanPaper/" + subjectUuId + "/" + zkzNum + "?imageUrl=" + imageUrl,
    });
}

// 查看考试二维码
export function getQRCode() {
    let zkzNum = localStorage.getItem("monitor-zkz");
    let subjectUuId = localStorage.getItem("monitor-subjectUuId");
    return request({
        method: "get",
        url: "/getQRCode/" + subjectUuId + "/" + zkzNum,
    });
}
